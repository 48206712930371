<template>
    <v-card width="200px"
            class="py-4 pl-1 pr-4 cat-item"
            style="cursor: pointer; position: relative; overflow: hidden;"
            @click.stop.prevent="redirect()"
    >
      <v-list-item :style="`height: ${height}px`">
        <v-list-item-avatar
          :size="height === 86 ? '54px' : '40px' "
          class="mr-6"
          :color="randomColor()"
        >
          <v-icon :size="height === 86 ? '30px' : '24px' " color="white" v-html="item.icon || 'mdi-home-city-outline'"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="subtitle-2 mb-1 text-wrap pt-1" style="line-height: 1.2rem">{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="right-bg"/>
    </v-card>

</template>

<script>
  import { shuffle } from 'lodash'
    export default {
      name: "CategoriesItem",
      // props: ['item', 'path'],
      props: {
        item: {
          type: Object,
          default() {
            return {}
          },
        },
        path: {
          type: String,
          default() {
            return "";
          }
        },
        modal: {
          type: Boolean,
          default() {
            return false
          }
        },
        openDialog: {
          type: Boolean,
          default() {
            return undefined
          }
        },
        height: {
          type: Number,
          default() {
            return 86
          }
        },
      },
      computed: {
        schema() {
          return this.$store.getters.getSchemaTree
        }
      },
      methods: {
        randomColor() {
          const colors = [
            'success lighten-1',
            'success',
            'primary',
            'red',
            'primary lighten-1',
            'orange',
            'red lighten-1',
            'orange darken-1',
            'purple',
            'red lighten-1',
            'success lighten-1',
          ];

          let shuffled = shuffle(colors);

          let randomIndex = Math.floor(Math.random() * (colors.length));

          return shuffled[randomIndex];
        },
        redirect: function () {
          let cPath = this.path;
          let pathArray = cPath.split('/');
          const lastEl = pathArray[pathArray.length - 1];
          const prevEl = pathArray[pathArray.length - 2];
          let index = 0;

          if (lastEl === 'templates') {
            index = 0
          } else {
            if (lastEl === '') {
              if (prevEl === 'templates') {
                index = 0
              } else {
                index = prevEl;
              }
            } else {
              index = lastEl;
            }
          }

          let childCount = this.$store.getters.getChildrensCount(index);
          if (childCount) {
            // this.$router.push(this.path)
            if (this.modal) {
              this.$emit('changePath', this.path)
            } else {
              this.$router.push(this.path)
            }
          } else {
            let params = {
              schema: this.schema,
              group: index.toString(),
            }

            if (this.modal) {
              this.$eventBus.$emit("modalRoute", Object.assign({}, params, { component: 'sub-categories-list', openDialog: this.openDialog } ))
            } else {
              this.$router.push(
                { name: "services-view",
                  params,
                  query: { component: true }
                })
            }
          }
        },
      }
    }
</script>

<style scoped>
  .right-bg {
    width: 30%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  .cat-item:hover {
    box-shadow: -1px 2px 7px 4px #808080a8 !important;
    transition: .5s;
  }
</style>
