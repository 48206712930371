<template>
  <div class="templates">
    <div class="grey lighten-4" v-if="showTitle">
      <v-container fluid class="container-max-width py-2 grey--text text--darken-2">
        <div class="grey lighten-4 py-2">
          <v-row no-gutters class="flex-nowrap" align="start">
              <span>
                <v-icon
                  size="56px"
                  color="primary"
                  class="pr-4">
                  mdi-google-circles-group
                </v-icon>
              </span>
            <div>
              <h3 class="text-uppercase">Групи послуг</h3>
              <p class="mb-1">Для зручності створення платежу ми усе погрупували... Оберіть одну із списку</p>
            </div>
          </v-row>
        </div>
      </v-container>
    </div>

    <v-container fluid class="container-max-width py-0 grey--text text--darken-2 mt-4" v-if="region">
      <v-row class="pa-3" :class="background">
        <v-col cols="12" md="12" sm="12" xs="12" class="mb-1 pt-2 pb-1">
          <v-select
            :items="regions"
            :label="$vuetify.lang.t('$vuetify.account.address_dialog.region')"
            required
            outlined
            hide-details
            v-model="region"
            item-text="name"
            item-value="schema"
            return-object
            tabindex="1"
            @change="changeRegion"
          />
        </v-col>
        <v-col cols="12" md="12" sm="12" xs="12" class="mt-0 mb-1 py-0" v-if="geoRegion && showGeo">
          <v-alert prominent class="py-0 my-0" color="green lighten-2">
            <v-row align="center">
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 2 : undefined" class="shrink">
                <v-icon size="28px" color="green darken-2" :class="$vuetify.breakpoint.xsOnly ? 'pr-4' : ''">mdi-map-marker</v-icon>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 8 : undefined" class="grow white--text pl-1">Ми визначили, що Ви знаходитися в {{ geoRegion.name_printable }}</v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 2 : undefined" class="shrink" v-if="$vuetify.breakpoint.xsOnly">
                <v-btn fab x-small outlined color="white">х</v-btn>
              </v-col>
              <v-col class="shrink">
                <v-btn tile outlined small color="white" @click="setGeoRegion">Змінити область</v-btn>
              </v-col>
              <v-col class="shrink" v-if="$vuetify.breakpoint.smAndUp">
                <v-btn fab x-small outlined color="white" @click="showGeo = false">х</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="getBreadcrumbs" class="py-0">
          <v-card tile flat elevation="0" color="transparent">
            <ul class="breadcrumbs-custom">
              <li v-for="(item, index) in getBreadcrumbs" :key="index" :class="item.separator ? 'breadcrumbs-separator' : ''">
                <router-link v-if="!innerPath" :to="item.href" tag="a" :class="item.disabled ? 'bread-non-active' : 'bread-active'">{{ item.text }}</router-link>
                <a v-else :to="item.href" :class="item.disabled ? 'bread-non-active' : 'bread-active'" @click.prevent.stop="onPathChange(item.href)">{{ item.text }}</a>
              </li>
            </ul>
          </v-card>
        </v-col>
        <v-container fluid class="ma-0 pt-0">
          <v-row align="center" style="min-height: 100px;">
            <v-col
              xs="12"
              md="4"
              sm="6"
              xl="3"
              v-for="(item, index) in items" :key="index"
              class="d-flex child-flex">
              <categories-item :item="item" :path="createPath(item.slug)" @changePath="onPathChange" :modal="modal" :openDialog="openDialog" :height="height"/>
            </v-col>
          </v-row>
        </v-container>

      </v-row>
    </v-container>
  </div>
</template>

<script>
    import CategoriesItem from "./CategoriesItem";
    import { TREE_GET_ALL, TREE_SCHEMA_SET, TREE_GET_SCHEMA_TREE, TREE_REGIONS } from '../../store/modules/tree/mutation-types'
    import geoAPI from '@/api/geo'

    const USE_GEO = true;

    export default {
      name: "CategoriesList",
      components: { CategoriesItem },
      props: {
        innerPath: { type: String },
        route: { type: String },
        modal: { type: Boolean },
        openDialog: { type: Boolean },
        height: { type: Number },
        showTitle: { type: Boolean, default: true },
        background: { type: String, default: 'white' }
      },
      data() {
        return {
          geoRegion: null,
          showGeo: true,
          categories: [],
          region: null,
          componentPath: this.innerPath ? this.innerPath : this.$route.path
        }
      },
      computed: {
        items() {
          // here was this.$route.path
          let cPath = this.componentPath;
          let pathArray = cPath.split('/');
          const lastEl = pathArray[pathArray.length - 1];
          const prevEl = pathArray[pathArray.length - 2];
          let op = '';
          let index = 0;

          if (lastEl === 'templates') {
            op = 'root';
          } else {
            if (lastEl === '') {
              if (prevEl === 'templates') {
                op = 'root';
              } else {
                op = 'id';
                index = prevEl;
              }
            } else {
              op = 'id';
              index = lastEl;
            }
          }

          if (op === 'root') {
            return this.$store.getters.getRoot;
          } else {
            return  this.$store.getters.getChildrens(index);
          }
        },
        regions() {
          return  this.$store.getters.getTreeRegions;
        },
        getBreadcrumbs() {
          if (this.items === undefined || this.items.length === 0 ) return [];
          const elem = this.items[0];
          if (!elem) return [];
          let url = elem.url;
          const cPath = this.path;
          let breadcrumbs = elem.breadcrumbs;
          let aItems = url.split('/');
          aItems = aItems.slice(1, aItems.length-1);

          let newItems = [];
          let href = '';

          aItems.forEach((item, index) => {

            href += '/templates' + '/' + item;
            let newObg = {
              text: breadcrumbs[index],
              disabled: href === cPath,
              href,
              separator: false,
            };

            newItems.push(newObg);

            if (index + 1 < aItems.length) {
              newItems.push({ text: '/',  disabled: true,  href: '',  separator: true });
            }
          });

          let startArray = [];
          startArray.push({ text: 'Перелік послуг',  disabled: false,  href: '/templates',  separator: false });
          if (newItems.length > 0) {
            startArray.push({ text: '/',  disabled: true,  href: '',  separator: true });
          }
          newItems = startArray.concat(newItems);

          return newItems;


        },
      },
      methods: {
        async getData() {
          return await this.$store.dispatch(TREE_GET_ALL);
        },
        async getFilteredData(e) {
          // await this.$store.dispatch(TREE_SCHEMA_SET, e);
          await this.$store.dispatch(TREE_GET_SCHEMA_TREE, e);
        },
        createPath(id) {
          // here was this.$route.path
          let cPath = this.componentPath;
          if (cPath.endsWith('/')) {
            cPath += id;
          } else {
            cPath += '/' + id;
          }

          return cPath;
        },
        async changeRegion() {
          await this.$store.dispatch(TREE_SCHEMA_SET, this.region.schema);
          if (this.geoRegion && this.region.schema === this.geoRegion.schema) {
            this.geoRegion = null;
          }
          await this.getFilteredData(this.region.schema);
        },
        async setGeoRegion() {
          this.region = this.geoRegion;
          this.geoRegion = null;
          await this.$store.dispatch(TREE_SCHEMA_SET, this.region.schema);
          await this.getFilteredData(this.region.schema);
        },
        getGeoSchema() {
          // here was this.$route.path
          if (this.componentPath === '/templates' || this.componentPath === '/templates/' && this.showGeo && USE_GEO) {
            this.$getLocation(geoAPI.GEO_OPTIONS)
              .then(async coordinates => {
                let {data} = await geoAPI.getGeoData(coordinates.lat, coordinates.lng);
                // const country = {...data.results[0].address_components[1]};
                const region = {...data.results[0].address_components[0]};

                const fRegion = geoAPI.REGIONS_UA.filter(item => item.name === region.long_name);
                if (fRegion.length > 0 && fRegion[0].schema) {
                  const geoRegion = fRegion[0].schema;
                  if (geoRegion !== this.region.schema) {
                    this.geoRegion = this.regions.filter(item => item.schema === geoRegion)[0] || undefined;
                    let name_printable = this.geoRegion.name || '';
                    name_printable = name_printable.replace('ська', 'ській області');
                    this.geoRegion = Object.assign({ name_printable: name_printable }, this.geoRegion);
                  }
                }
              })
              .catch(() => {
                  this.$snackbar('Для автоматичного визначення області - включіть геолокацію', 'warning')
                }
              )
          } else {
            this.geoRegion = null;
          }
        },
        fetchData() {
          this.$store.dispatch(TREE_REGIONS).then(() => {
            this.$store.dispatch(TREE_SCHEMA_SET, '').then(() => {
              this.region = this.$store.getters.getRegionObject;
              this.$store.dispatch(TREE_GET_ALL).then(() => {
                this.region = this.$store.getters.getRegionObject;
                this.getFilteredData(this.$store.getters.getRegionObject.schema)
              })
            })
          })
        },
        onPathChange(path) {
          this.componentPath = path;
          this.$emit("onPathChange", this.componentPath)
        },
      },
      watch: {
        region() {
          this.changeRegion();
        }
      },
      mounted() {
        this.fetchData();
        this.getGeoSchema();
      }
    }
</script>

<style scoped lang="scss">
  .container-max-width {
    max-width: 1200px;
  }
  .breadcrumbs-custom {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      padding: 1px 6px 1px 3px;
      /*border: .5px solid #1976d24f;*/
      cursor: pointer;

      a {
        display: block;
        text-decoration: none;
      }

      .bread-non-active {
        cursor: default;
        pointer-events: none;
        color: rgba(0, 0, 0, 0.38);
      }
    }

    .breadcrumbs-separator {
      border: none;
      pointer-events: none;
    }
  }
</style>
