import axios from 'axios';
const API_KEY = "AIzaSyCYkFTKCetSWo5bU0BZpbihBdOZKDV6mY4";

function getURL(lat, lng) {
  return `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}&language=uk&result_type=administrative_area_level_1|administrative_area_level_1`
}

const REGIONS_UA = [
  { name: "Автономна республіка Крим", schema: ""},
  { name: "Вінницька область", schema: "" },
  { name: "Волинська область", schema: "" },
  { name: "Дніпропетровська область", schema: "" },
  { name: "Донецька область", schema: "" },
  { name: "Житомирська область", schema: "" },
  { name: "Закарпатська область", schema: "" },
  { name: "Запорізька область", schema: "" },
  { name: "Івано-Франківська область", schema: "ifrankivsk" },
  { name: "Київська область", schema: "kyiv" },
  { name: "Кіровоградська область", schema: "" },
  { name: "Луганська область", schema: "" },
  { name: "Львівська область", schema: "lviv" },
  { name: "Миколаївська область", schema: "" },
  { name: "Одеська область", schema: "" },
  { name: "Полтавська область", schema: "" },
  { name: "Рівненська область", schema: "rivne" },
  { name: "Сумська область", schema: "" },
  { name: "Тернопільська область", schema: "ternopil" },
  { name: "Харківська область", schema: "" },
  { name: "Херсонська область", schema: "" },
  { name: "Хмельницька область", schema: "khmelnytskyi" },
  { name: "Черкаська область", schema: "" },
  { name: "Чернівецька область", schema: "" },
  { name: "Чернігівська область", schema: "" },
]

const GEO_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
}

export default {
  getGeoData(lat, lng) {
    return axios.get(getURL(lat, lng));
  },
  REGIONS_UA,
  GEO_OPTIONS
}
